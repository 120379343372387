import revive_payload_client_YTGlmQWWT5 from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_xKuw1km5sV from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_muy1gU4FCL from "/vercel/path0/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import formkitPlugin_pZqjah0RUG from "/vercel/path0/apps/web/.nuxt/formkitPlugin.mjs";
import slideovers_oeUu8xcI5w from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_qI5jN0JlD2 from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_LeAKZ8VmiU from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_VfWFs4sWWP from "/vercel/path0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_l138bYv0gX from "/vercel/path0/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import chunk_reload_client_SeG0EjL5Ec from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auth0_ZnwwTmFbx2 from "/vercel/path0/apps/web/plugins/auth0.ts";
import markdown_nljPFoZ1ix from "/vercel/path0/apps/web/plugins/markdown.ts";
import tippy_xFBYqfAPCu from "/vercel/path0/apps/web/plugins/tippy.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  check_outdated_build_client_LIYcCMJD18,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_muy1gU4FCL,
  formkitPlugin_pZqjah0RUG,
  slideovers_oeUu8xcI5w,
  modals_qI5jN0JlD2,
  colors_LeAKZ8VmiU,
  plugin_client_VfWFs4sWWP,
  plugin_l138bYv0gX,
  chunk_reload_client_SeG0EjL5Ec,
  auth0_ZnwwTmFbx2,
  markdown_nljPFoZ1ix,
  tippy_xFBYqfAPCu
]